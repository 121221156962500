import React, { useState, useEffect, useContext } from "react";
import { PageProps } from "gatsby";
import { useMediaQuery } from "@material-ui/core";
import { SEO } from "@components";
import { LayoutMissionDesktop, LayoutMissionMobile } from "@components";
import { useAuth, useForm } from "@hooks";
import { AppContext } from "@context";
import { Route } from "@interfaces";

// const { userMissions } = getUserMissions(); //!! to update mission
// const client = useApolloClient(); //!! to update mission
// const categoryMissions = userMissions.filter((obj) => obj.mission.category == MissionsInfo.ProfileCategory.name)
// const currentMission = categoryMissions.filter((obj) => obj.mission.id == MissionsInfo.ProfileCategory.m2.id)[0]
// const handleClick = () => {
//   if(currentMission?.state == "PENDING"){
//     try {
//       updateUser(form, true, client)
//       completeMissionMutation(client, currentMission.mission.id)
//       openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions))
//     } catch (error) {
//       console.log(error)
//     }
//   } else {
//     openCongratsModal()
//   }
// }

const M6Pack: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery("(min-width: 1240px)");
  const { openCongratsModal } = useContext(AppContext);
  const [form, , , handleChange] = useForm({});

  const handleClick = () => {
    openCongratsModal();
  };

  return (
    <>
      <SEO />
      {isMobile ? (
        <LayoutMissionDesktop title={"6"} subtitle={"Mision"} number={6}>
          <div>Mision 6</div>
        </LayoutMissionDesktop>
      ) : (
        <LayoutMissionMobile title={"6"} subtitle={"Mision"} number={6}>
          <div>Mision 6</div>
        </LayoutMissionMobile>
      )}
    </>
  );
};

export default M6Pack;
